import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { IPublished } from '@common/typescript/objects/IPublished';
import { List } from '@common/typescript/objects/List';
import { Page as BasePage } from '@common/typescript/objects/Page';

import { FileInterface } from '@common/typescript/objects/FileInterface';

import { Ethnicity } from '@commonTuna/react/objects/Ethnicity';
import { Gender } from '@commonTuna/react/objects/Enums';
import { BaseDoctor } from '@commonTuna/react/objects/BaseDoctor';

export interface BaseCasePage extends BasePage {
	fullPath?: string
	fullPathEs?: string;
}

export enum CaseType {
	BreastAugmentation = 0,
	Other = 1
}

export interface DoctorCase<Doctor extends BaseDoctor = BaseDoctor, C extends Case = any> extends WithDeleted {
	doctorId: number;
	caseId: number;
	doctor: Doctor;
	case: C;
}

export interface CasePage<Page extends BaseCasePage = BaseCasePage, C extends Case = any> extends WithDeleted {
	caseId: number;
	pageId: number;
	case: C;
	page: Page;
}

export enum CaseImageType
{
	Before = 0,
	After = 1
}

export interface CaseImage extends WithDeleted {
	fileId: number;
	objectId: number;
	file: FileInterface;
	primary: boolean;
	imageType: CaseImageType;
	order: number;
}

export interface Case<Page extends CasePage = CasePage, Doctor extends DoctorCase = DoctorCase> extends WithDeleted, IPublished {
	nameEn: string;
	nameEs: string;
	descriptionEn: string;
	descriptionEs: string;
	age: number;
	weight: number;
	height: number;
	gender: Gender;
	ethnicity?: Ethnicity;
	ethnicityId: number | null;
	files: List<CaseImage>;
	pages: Array<Page>;
	doctors: Array<DoctorCase>;
	before: string;
	after: string;
	preBra: string;
	postBra: string;
	leftImplant: string;
	rightImplant: string;
	rate: number;
	caseType: CaseType;
	indexInCompany: number;
}
