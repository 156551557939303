import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { imageSize } from '@common/react/utils/utils';
import useRequest from '@common/react/hooks/useRequest';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';

import { Gender } from '@commonTuna/react/objects/Enums';
import {
	CaseType, Case, CasePage, DoctorCase,
} from '@commonTuna/react/objects/BaseCase';
import ImageWithSkeleton from '@commonTuna/react/components/UI/ImageWithSkeleton/ImageWithSkeleton';

import '@commonTuna/react/scss/components/caseModal.scss';

interface ComponentProps<Page extends CasePage = CasePage, Doctor extends DoctorCase = DoctorCase> {
	lang: string;
	t: (string) => React.ReactNode;
	caseId: number | null;
	defaultItem?: Case<any, any>;
	leftClick?: () => void;
	rightClick?: () => void;
	loadRequest?: string;
	renderServiceTag?: (service, lang) => React.ReactNode;
	defaultSelectedPair?: Pair;
}

interface Pair {
	left: string;
	right: string;
}

export const ethnicities = {
	'american indian or alaska native': 'American Indian',
	'black or african american': 'Black or African American',
	'hispanic or latino': 'Hispanic or Latino',
	'native hawaiian or other pacific islander': 'Native Hawaiian',
	'india americana o nativa de alaska': 'India Americana',
	'nativa hawaiana u otra isleña del pacífico': 'Nativa Hawaiana',
};

const defaultRenderService = (page, lang) => {
	const path = (lang === 'En' ? page.page?.fullPath : page.page?.fullPathEs) || '';
	const tag = (<div className="ant-tag" key={page.page.id} style={{ fontWeight: 600 }}>
		{page.page[`shortName${lang}`]}
	</div>);

	return path ? <NavLink
		key={page.id}
		to={(lang === 'En' ? page.page?.fullPath : page.page?.fullPathEs) || ''}
	>
		{tag}
	</NavLink> : tag;
};

const CaseModalInner = <Page extends CasePage = any, Doctor extends DoctorCase = any>(props: ComponentProps<Page, Doctor>) => {
	const {
		caseId,
		leftClick,
		rightClick,
		defaultItem,
		lang,
		t,
		loadRequest = 'getCaseViewRemote',
		renderServiceTag = defaultRenderService,
		defaultSelectedPair,
	} = props;
	const [item, setItem] = React.useState((defaultItem || {}) as Case);
	const [selectedPair, setSelectedPair] = React.useState<Pair | undefined>(defaultSelectedPair);
	const request = useRequest();

	React.useEffect(() => {
		if (item.id !== caseId && caseId && caseId > 0) {
			request<Case>(loadRequest, {
				id: caseId,
			}).then((res) => {
				setItem({
					...res,
					files: { ...res.files, list: [...res.files.list].reverse() },
				} as Case);
				setSelectedPair(undefined);
			});
		}
	}, [props.caseId]);

	React.useEffect(() => {
		if (leftClick && rightClick) {
			const buttonClick = (e) => {
				const code = e.code;

				if (code === 'ArrowLeft') {
					leftClick();
				} else if (code === 'ArrowRight') {
					rightClick();
				}
			};

			document.addEventListener('keydown', buttonClick);

			return () => {
				document.removeEventListener('keydown', buttonClick);
			};
		}
	});

	if (item && item.files && item.id === caseId) {
		const files = item.files.list || (item as any).files;
		const pairs: Array<any> = [];

		for (let i = 0; i < item.files.count - 1; i += 2) {
			pairs.push({
				left: imageSize(files[i].file.src, '_main'),
				right: imageSize(files[i + 1].file.src, '_main'),
			});
		}

		return (
			<>
				<div className="case-modal__title" title={item[`name${lang}`]}>
					{`Case #${item.indexInCompany || '-'} `}
					{item[`name${lang}`]}
				</div>
				<div className="case-modal__photos row">
					<div className="col-xs-6">
						<div className="square-block">
							<div className="case-modal__photo">
								<ImageWithSkeleton
									imageHiddenClassName="hide-case-image"
									loaderContainerClassName="case-image__loader"
									src={selectedPair ? selectedPair.left : imageSize(item.before, '_main')}
									alt="Before"
								/>
							</div>
						</div>
					</div>
					<div className="col-xs-6">
						<div className="square-block">
							<div className="case-modal__photo">
								<ImageWithSkeleton
									imageHiddenClassName="hide-case-image"
									loaderContainerClassName="case-image__loader"
									src={selectedPair ? selectedPair.right : imageSize(item.after, '_main')}
									alt="After"
								/>
							</div>
						</div>
					</div>
				</div>
				{pairs.length > 1 ? <div className="case-modal__pairs row">
					{pairs.map(((pair, index) => ({ pair, index }))).map(({ pair, index }) => (
						<div className="col-xs-2" key={index}>
							<div className="square-block">
								<div className="case-modal__thumb" style={{ cursor: 'pointer' }} onClick={() => setSelectedPair(pair)}>
									<ImageWithSkeleton src={pair.right} alt="Click to see results" />
									<span className="case-modal__caption">{t('site.Click to see results')}</span>
								</div>
							</div>
						</div>
					))}
				</div> : null}
				<div className="case-modal__body">
					{item.pages?.length ? <div className="row">
						<div className="col-sm-12">
							<label className="param-label">
								{lang === 'En' ? `Service${item.pages?.length > 1 ? 's' : ''}` : t('site.Services')}
								:
							</label>
							{item.pages.length && item.pages.map((page) => renderServiceTag(page, lang))}
						</div>
					</div> : null}

					<div className="case-data">
						<div className="case-data-item">
							<label className="param-label">
								{t('forms.Gender')}
								:
							</label>
							{t(`forms.${Gender[item.gender]}`)}
						</div>
						<div className="case-data-item">
							<label className="param-label">
								{t('forms.Ethnicity')}
								:
							</label>
							{item.ethnicity ? ethnicities[item.ethnicity[`name${lang}`].toLowerCase()] || item.ethnicity[`name${lang}`] : 'N/A'}
						</div>
						<div className="case-data-item">
							<label className="param-label">
								{t('forms.Age')}
								:
							</label>
							{item.age || 'N/A'}
&nbsp;
							<span className="mobile-hidden">{item.age ? lang === 'En' ? 'year(s)' : t('forms.Age') : ''}</span>
						</div>
						<div className="case-data-item">
							<label className="param-label">
								{t('forms.Weight')}
								:
							</label>
							{item.weight || 'N/A'}
							<span className="mobile-hidden">&nbsp;</span>
							{item.weight ? 'lb' : ''}
						</div>
						<div className="case-data-item">
							<label className="param-label">
								{t('forms.Height')}
								:
							</label>
							{item.height || 'N/A'}
							<span className="mobile-hidden">&nbsp;</span>
							{item.height ? 'ft' : ''}
						</div>
					</div>
					{item.caseType === CaseType.BreastAugmentation && <>
						<div className="case-data">
							<div className="case-data-item">
								<label className="param-label">
									{t('forms.Pre-op Size')}
									:
								</label>
								{item.preBra || 'N/A'}
							</div>
							<div className="case-data-item">
								<label className="param-label">
									{t('forms.Post-op Size')}
									:
								</label>
								{item.postBra || 'N/A'}
							</div>
							<div className="case-data-item">
								<label className="param-label">
									{t('forms.Left Implant')}
									:
								</label>
								{item.leftImplant || 'N/A'}
							</div>
							<div className="case-data-item">
								<label className="param-label">
									{t('forms.Right Implant')}
									:
								</label>
								{item.rightImplant || 'N/A'}
							</div>
						</div>
					</>}
					{item[`description${lang}`] ? <div className="row">
						<div className="col-sm-12">
							<label className="param-label">
								{t('site.Description')}
								:
							</label>
							{item[`description${lang}`]}
						</div>
					</div> : null}
				</div>

				{leftClick
					? <i className="fa fa-caret-left case-modal__arrow case-modal__arrow_prev" aria-hidden="true" onClick={leftClick} />
					: null}
				{rightClick
					? <i className="fa fa-caret-right case-modal__arrow case-modal__arrow_next" aria-hidden="true" onClick={rightClick} />
					: null}
			</>
		);
	}

	return <Loader />;
};

export default CaseModalInner;
