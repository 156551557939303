import * as React from 'react';
import { useTranslation } from 'react-i18next';

import LockContentCommon from '@common/react/components/UI/LockContent/LockContent';

import '@app/scss/components/lockContent.scss';

const LockContent: React.FC<{fixed?: boolean}> = ({ fixed }) => {
	const { t } = useTranslation();

	return <LockContentCommon
		title={t('site.WARNING')}
		subTitle={t('site.This feature contains nudity')}
		solveTop={(top) => top + (window.innerWidth < 768 ? 40 : 240)}
		fixed={fixed}
	/>;
};

export default LockContent;
