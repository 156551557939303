import * as React from 'react';

import Button from '@common/react/components/Forms/Button';
import { getLocalStorageValue, setLocalStorageValue } from '@common/react/utils/localStorage/localStorage';
import ResizeObserverContainer from '@common/react/components/UI/ResizeObserverContainer/ResizeObserverContainer';

import '@common/react/scss/components/lockContent.scss';

interface LockContentProps {
	localStorageKey?: string;
	fixed?: boolean
	title?: string;
	subTitle?: string;
	closeButton?: (unlock: VoidFunction) => React.ReactNode;
	solveTop?: (elementTop: number) => number;
	initTop?: number;
}

const LockContent: React.FC<LockContentProps> = (props) => {
	const {
		localStorageKey = 'lock-cases',
		fixed,
		subTitle,
		title,
		closeButton = (unlock) => <div className="text-center">
			<Button className="btn btn-default" onClick={unlock}>
				OK
			</Button>
		</div>,
		initTop = 400,
		solveTop,
	} = props;
	const [lock, setLock] = React.useState(false);

	React.useEffect(() => {
		setLock(JSON.parse(getLocalStorageValue(localStorageKey, 'true')));
	}, []);

	const unlock = () => {
		setLocalStorageValue(localStorageKey, false);
		setLock(false);
	};

	const [top, setTop] = React.useState(initTop);

	if (!lock) {
		return null;
	}

	const content = (<div className={`lock-content ${fixed ? 'lock-content-fixed' : ''}`} style={{ top }} key="content">
		{title && <h2>
			{title}
		</h2>}
		{subTitle && <p>
			{subTitle}
		</p>}
		{closeButton(unlock)}
	</div>);

	return <>
		<ResizeObserverContainer
			onResize={({ ref }) => {
				const rect = ref.current?.getBoundingClientRect();
				if (rect && solveTop) {
					setTop(solveTop(rect.top));
				}
			}}
			className="lock-container text-center"
			key="blur"
		>
			{fixed ? null : content}
		</ResizeObserverContainer>
		{fixed ? content : null}
	</>;
};

export default LockContent;
